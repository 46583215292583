<template>
  <FormulateForm ref="formulateCancelledData">
    <b-row>
      <b-col md="3">
        <FormulateInput
          id="supplier-invoice-cancel-date"
          v-model="invoiceIssuerCancelledEvent.eventDate"
          type="label"
          filter="datetime"
          :label="$t('Cancelado em')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="supplier-invoice-cancel-date"
          v-model="invoiceIssuerCancelledEvent.protocol"
          type="label"
          :label="$t('Protocolo do cancelamento')"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="supplier-invoice-cancel-justification"
          v-model="invoiceIssuerCancelledEvent.justification"
          type="label"
          :label="$t('Justificativa')"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'SupplierForm',

  components: {
    BCol,
    BRow,
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },

    purchaseOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    invoiceIssuerCancelledEvent() {
      return this.purchaseOrder?.invoiceIssuerCancelledEvent || {}
    },
  },

  methods: {},
}
</script>

<style></style>
