var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateCancelledData" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-invoice-cancel-date",
                  type: "label",
                  filter: "datetime",
                  label: _vm.$t("Cancelado em"),
                },
                model: {
                  value: _vm.invoiceIssuerCancelledEvent.eventDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceIssuerCancelledEvent, "eventDate", $$v)
                  },
                  expression: "invoiceIssuerCancelledEvent.eventDate",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-invoice-cancel-date",
                  type: "label",
                  label: _vm.$t("Protocolo do cancelamento"),
                },
                model: {
                  value: _vm.invoiceIssuerCancelledEvent.protocol,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceIssuerCancelledEvent, "protocol", $$v)
                  },
                  expression: "invoiceIssuerCancelledEvent.protocol",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-invoice-cancel-justification",
                  type: "label",
                  label: _vm.$t("Justificativa"),
                },
                model: {
                  value: _vm.invoiceIssuerCancelledEvent.justification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.invoiceIssuerCancelledEvent,
                      "justification",
                      $$v
                    )
                  },
                  expression: "invoiceIssuerCancelledEvent.justification",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }