<template>
  <section>
    <b-card-actions
      :show-loading="uploading"
      action-collapse
      :title="isEdit
        ? `${$t('Nota Fiscal Importada')} - Pedido #${purchaseOrder.id}`
        : $t('Importar Nota Fiscal')
      "
    >
      <FormulateForm
        v-show="!uploading"
        ref="form"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              v-if="isEdit"
              id="file-name"
              :value="fileName"
              type="label"
              :label="$t('XML da Nota Fiscal')"
            />
            <FormulateInput
              v-else
              id="invoice-xml-file"
              ref="xmlFile"
              v-model="invoiceXmlFile"
              type="uploader"
              max-files="1"
              class="required"
              :theme="'list'"
              :deletable="!isEdit"
              :meta="true"
              :accept="'.xml'"
              :label="$t('XML da Nota Fiscal')"
              validation="required"
              :help-text="$t('Arraste o XML da sua Nota Fiscal aqui (ou clique para selecionar o arquivo)')
              "
              :delete-confirm-message="$t(
                'A remoção do arquivo fará com que a edição atual do Pedido seja descartada. Deseja continuar?'
              )
              "
              @input="uploadFile"
              @delete="removeFiles"
            />
          </b-col>
          <b-col
            v-if="storeLabel"
            md="6"
          >
            <FormulateInput
              id="store-label"
              v-model="storeLabel"
              type="label"
              :label="$t('Loja')"
            />
          </b-col>
          <b-col
            v-if="isEdit"
            md="6"
          >
            <FormulateInput
              id="status-label"
              :value="purchaseOrderStatus"
              type="label"
              :label="$t('Status')"
              :router-to="approvalFlowRoute"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <transition name="fade">
      <template v-if="purchaseOrder.loaded">
        <b-row>
          <b-col
            v-if="purchaseOrder.invoiceIssuerCancelledEvent"
            md="12"
          >
            <b-card-actions
              action-collapse
              :title="$t('Dados de Cancelamento da Nota Fiscal do Fornecedor')"
            >
              <cancelled-data :purchase-order="purchaseOrder" />
            </b-card-actions>
          </b-col>

          <b-col md="6">
            <b-card-actions
              :show-loading="saving"
              action-collapse
              :title="$t('Dados da Nota Fiscal')"
              class="h-345"
            >
              <invoice-form
                ref="invoiceForm"
                :purchase-order="purchaseOrder"
                is-read-only
              />
            </b-card-actions>
          </b-col>

          <b-col md="6">
            <b-card-actions
              :show-loading="saving"
              action-collapse
              :title="$t('Fornecedor')"
              class="h-345"
            >
              <supplier-form
                ref="supplierForm"
                :purchase-order="purchaseOrder"
                is-read-only
              />
            </b-card-actions>
          </b-col>

          <b-col md="12">
            <b-card-actions
              :show-loading="saving"
              action-collapse
              :title="$t('Itens do Pedido')"
            >
              <order-items
                ref="orderItems"
                is-xml
                :purchase-order="purchaseOrder"
                @update-average-prices="onUpdateItemsAveragePrices"
              />
            </b-card-actions>
          </b-col>

          <b-col md="12">
            <b-card-actions
              :show-loading="saving"
              action-collapse
              :title="$t('Pagamentos')"
            >
              <payments
                ref="paymentsTable"
                :purchase-order="purchaseOrder"
                is-xml
              />
            </b-card-actions>
          </b-col>
        </b-row>
      </template>
    </transition>

    <e-crud-actions
      :show-save="false"
      :busy="saving"
      :additional-actions="additionalActions"
      @cancel="onCancelSubmit"
      @reevaluate="onSubmitOrder"
      @submit="onSubmitOrder"
    />
  </section>
</template>

<script>
import delegablePermissions from '@/utils/delegable-permissions'
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
// import EButton from '@/views/components/EButton.vue'
import { purchaseOrderDomains, uploader } from '@/mixins'
import ECrudActions from '@/views/components/ECrudActions.vue'
import OrderItems from './components/OrderItems.vue'
import Payments from './components/Payments.vue'
import SupplierForm from './components/SupplierForm.vue'
import InvoiceForm from './components/InvoiceForm.vue'
import CancelledData from './components/CancelledData.vue'

export default {
  name: 'PurchaseOrderMaintain',

  components: {
    BRow,
    BCol,
    BCardActions,
    OrderItems,
    Payments,
    CancelledData,
    // EButton,
    SupplierForm,
    InvoiceForm,
    ECrudActions,
  },

  mixins: [uploader, purchaseOrderDomains],

  data() {
    return {
      mode: 'xml',
      loadingAveragePrices: false,
      // store: null,
      // invoiceXmlFile: [],
      uploading: false,
      saving: false,
      showAverageCostDiffWarning: false,
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapGetters('pages/purchase/purchaseOrder/purchaseOrderMaintain', [
      'readOnly',
      'approvalMessage',
    ]),
    ...mapState('pages/purchase/purchaseOrder/purchaseOrderMaintain', {
      purchaseOrder: 'purchaseOrder',
      lastApprovalFlow: 'lastApprovalFlow',
      stInvoiceXmlFile: 'invoiceXmlFile',
      stItemsPurchase: 'itemsPurchase',
      stAccountPayable: 'accountPayable',
      stXmlFileLoaded: 'xmlFileLoaded',
    }),
    ...mapGetters('app', ['storeOptions']),
    purchaseOrderStatus() {
      return (
        this.$t(`PurchaseOrder.Status.${this.purchaseOrder.status}`) +
        (this.approvalMessage ? ` (${this.approvalMessage})` : '')
      )
    },
    approvalFlowRoute() {
      if (this.lastApprovalFlow) {
        return {
          name: 'approval-flow-details',
          params: {
            id: this.lastApprovalFlow.id,
          },
        }
      }
      return null
    },
    invoiceXmlFile: {
      get() {
        return this.stInvoiceXmlFile
      },
      set(val) {
        this.setInvoiceXmlFile(val)
      },
    },
    hasFile() {
      return this.invoiceXmlFile.length > 0
    },
    isEdit() {
      return !!this.purchaseOrder.id
    },
    storeLabel() {
      return this.purchaseOrder?.store?.name
    },
    fileName() {
      // const [file] = this.invoiceXmlFile
      // return file?.name()
      return this.invoiceXmlFile?.name
    },
    hasPendencies() {
      return (
        !!this.purchaseOrder.items.find(item => !item.erpSku?.sku?.id) ||
        !!this.purchaseOrder.payments.find(payment => !payment.managementAccountId)
      )
    },
    additionalActions() {
      const { CHANGES_REQUESTED, REJECTED, DRAFT, IDENTIFIED } = this.mxPurchaseOrderStatusEnum
      return [
        {
          name: 'reevaluate',
          icon: 'refresh',
          color: 'green',
          tooltip: this.$t('Submeter para reavaliação'),
          show: [CHANGES_REQUESTED].includes(this.purchaseOrder.status),
        },
        {
          name: 'submit',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar'),
          show:
            !this.readOnly &&
            [REJECTED, DRAFT, IDENTIFIED].includes(this.purchaseOrder.status),
        },
      ].filter(item => item.show)
    },
  },

  created() {
    if (this.$route.params?.id) this.cleanState()
  },

  async mounted() {
    this.fetchManagementAccounts()
    if (this.$route.params?.id) {
      const { CHANGES_REQUESTED, REJECTED, SUBMITTED, ISSUER_INVOICE_CANCELLED } = this.mxPurchaseOrderStatusEnum
      try {
        this.uploading = true
        await this.fetchPurchaseOrder(this.$route.params?.id)
        if ([CHANGES_REQUESTED, REJECTED, SUBMITTED, ISSUER_INVOICE_CANCELLED].includes(this.purchaseOrder.status)) {
          await this.fetchPurchaseOrderLastApprovalFlow(this.$route.params?.id)
        }
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível obter os dados do Pedido de Compra'),
        })
        this.$router.back()
      } finally {
        this.uploading = false
      }
    } else if (['purchase-order-xml-import', 'purchase-order-add'].indexOf(this.$route.name) >= 0) {
      if (this.purchaseOrder?.invoiceKey) {
        const keepData = this.readOnly
          ? false
          : await this.confirm({
            title: this.$t('Manter edição anterior?'),
            text: this.$t(
              'Identificamos um Pedido que estava sendo editado anteriormente. Deseja manter estes dados?'
            ),
            confirmButtonText: this.$t('Manter os dados'),
            cancelButtonText: this.$t('Descartar os dados'),
          })
        if (!keepData) {
          this.cleanState()
        }
      }
    }
  },

  methods: {
    ...mapActions('pages/purchase/purchaseOrder', ['fetchManagementAccounts']),
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', [
      'savePurchaseOrder',
      'reevaluatePurchaseOrder',
      'fetchPurchaseOrder',
      'fetchPurchaseOrderLastApprovalFlow',
      'setInvoiceXmlFile',
      'setXmlFileLoaded',
      'cleanState',
      'cleanStateTabs',
      'sendXmlInvoice',
      'loadItemsAveragePrice',
    ]),

    async uploadFile() {
      if (this.isEdit || !this.invoiceXmlFile?.length) return
      this.$refs.form.showErrors()
      try {
        this.uploading = true
        await this.sendXmlInvoice({ file: this.invoiceXmlFile[0], storeId: this.store })
        // this.showSuccess({ message: this.$t('Importado com sucesso') })
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível fazer a leitura da Nota Fiscal'),
        })
        this.cleanState()
      } finally {
        this.uploading = false
      }
    },

    async removeFiles() {
      this.cleanState()
      this.invoiceXmlFile = []
    },

    async onReevaluateOrder() {
      console.log('teste')
    },

    async onSubmitOrder() {
      if (!this.purchaseOrder.loaded) {
        this.showWarning({
          message: this.$t(
            'Nenhuma Nota Fiscal foi importada. Importe uma Nota Fiscal para gerar o Pedido de Compra.'
          ),
        })
        return
      }
      try {
        let confirmed = false
        if (this.hasPendencies) {
          confirmed = await this.confirm({
            icon: 'question',
            title: this.$t('Gravar como Rascunho?'),
            text: this.$t(
              'Ainda há pendências no Pedido. Deseja gravar como rascunho? Os dados serão gravados, mas nenhuma movimentação de estoque ou preço será realizada.'
            ),
          })
        } else {
          confirmed = await this.confirm({
            title: this.$t('ATENÇÃO: Confirma Gravação?'),
            text: this.$t(
              'Após a gravação, não será permitido realizar nenhuma alteração no Pedido. Estoque e preço médio dos produtos serão atualizados.'
            ),
          })
        }

        if (confirmed) {
          if (this.$refs.orderItems.validateForm()) {
            this.showError({
              message: this.$t('A quantidade deve ser maior que zero'),
            })
            return
          }

          if (this.$refs.paymentsTable.validateForm()) {
            this.showError({
              message: this.$t('Em pagamentos'),
            })
            return
          }

          this.saving = true
          let data

          if (this.purchaseOrder.status === 'ChangesRequested') {
            await this.reevaluatePurchaseOrder()
          } else {
            data = await this.savePurchaseOrder({ file: this.invoiceXmlFile?.[0] })
          }

          this.actionsAfterSave(data)
        }
      } catch (error) {
        if (Array.isArray(error.response?.data?.Message)) {
          await this.confirmRevision(error.response?.data?.Message[0])
        } else {
          this.showError({ error })
        }
      } finally {
        this.saving = false
      }
    },

    async confirmRevision(errorMessage) {
      const htmlMessage = this.mxGetAverageCostDiffAlertMsg(errorMessage)

      if (!this.showAverageCostDiffWarning) {
        await this.confirmHtml({
          title: this.$t('Atenção'),
          html: htmlMessage,
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
        })
        this.showAverageCostDiffWarning = true
        return
      }

      try {
        const confirmed = await this.confirmHtml({
          title: this.$t('Atenção'),
          html: htmlMessage,
          confirmButtonText: this.$t('Prosseguir, eu já revisei'),
          cancelButtonText: this.$t('Revisar'),
          focusCancel: true,
        })

        if (confirmed) {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.ERP_PURCHASE_ORDER_DIFF_AVERAGE_PRICE,
            this.purchaseOrder?.store?.id
          )
          const data = await this.savePurchaseOrder({
            itemsUnitValueRevision: true,
            tokenDelegated: this.delegateUserData?.token,
            file: this.invoiceXmlFile?.[0]
          })
          this.actionsAfterSave(data)
        }
      } catch (error) {
        if (error.name !== 'Cancel') {
          this.showError({ error })
        }
      }
    },

    async onCancelSubmit() {
      const isConfirmed =
        this.readOnly ||
        !this.purchaseOrder.loaded ||
        (await this.confirm({
          title: this.$t('Deseja mesmo cancelar?'),
          text: this.$t('Os dados preenchidos serão perdidos'),
        }))
      if (isConfirmed) {
        this.cleanState()
        this.$router.push({ name: 'purchase-order-list' })
      }
    },

    actionsAfterSave(data) {
      if (typeof data?.message === 'string' && data?.message?.length > 0) {
        this.showWarning({ message: this.$t(data.message) })
      } else {
        this.showSuccess({ message: this.$t('Salvo com sucesso') })
      }

      this.cleanState()
      this.$router.push({ name: 'purchase-order-list' })
    },

    async onUpdateItemsAveragePrices() {
      try {
        this.loadingAveragePrices = true

        this.loadItemsAveragePrice()
      } catch (e) {
        this.showWarning({
          message: this.$t('Não foi possível encontrar o preço e o preço de custo no ERP'),
        })
      } finally {
        this.loadingAveragePrices = false
      }
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

/* .h-345 .card {
  min-height: 345px;
} */
</style>
